@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: "Cristone";
    src: url('../src/assets/fonts/Cristone.ttf');
}

@font-face {
    font-family: "Blatan";
    src: url('../src/assets/fonts/Blatant.otf');
}

@font-face {
    font-family: "bahnschrift";
    src: url('../src/assets/fonts/bahnschrift.ttf');
}